@font-face {
  font-family: 'Avenir Light Oblique';
  src: url("font/Avenir-LightOblique.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Book Oblique';
  src: url("font/Avenir-BookOblique.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Black Oblique';
  src: url("font/Avenir-BlackOblique.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Light';
  src: url("font/Avenir-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Book';
  src: url("font/Avenir-Book.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Heavy';
  src: url("font/Avenir-Heavy.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Heavy Oblique';
  src: url("font/Avenir-HeavyOblique.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Black';
  src: url("font/Avenir-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Oblique';
  src: url("font/Avenir-Oblique.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Medium';
  src: url("font/Avenir-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir Medium Oblique';
  src: url("font/Avenir-MediumOblique.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Avenir Roman';
  src: url("font/Avenir-Roman.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

.text1 {
  font-family: "Avenir Light Oblique";
}

.text2 {
  font-family: "Avenir Book Oblique";
}

.text3 {
  font-family: "Avenir Black Oblique";
}

.text4 {
  font-family: "Avenir Light";
}

.text5 {
  font-family: "Avenir Book";
}

.text6 {
  font-family: "Avenir Heavy";
}

.text7 {
  font-family: "Avenir Heavy Oblique";
}

.text8 {
  font-family: "Avenir Black";
}

.text9 {
  font-family: "Avenir Oblique";
}

.text10 {
  font-family: "Avenir Medium";
}

.text11 {
  font-family: "Avenir Medium Oblique";
}

.text12 {
  font-family: "Avenir Roman";
}

.clickable {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.banner-pre {
  white-space: pre-line;
}

.banner-link a {
  color: #168fd5;
  text-decoration: none;
}

.banner-link a:hover {
  color: #004862;
  text-decoration: none;
}

.banner-link.orange-bg a {
  color: #168fd5;
}

.banner-link.orange-bg a:hover {
  color: #004862;
}

.bbn {
  border-bottom: solid 3px #eaeaea;
}

.rollup {
  height: 50px;
  overflow: hidden;
  transition: 1s;
}

.rollup.ng-hide {
  height: 0;
  padding: 0 30px;
}

@media (max-width: 992px) {
  .rollup-drawer {
    height: 100vh;
    transition: 1s;
  }
  .rollup-drawer.ng-hide {
    height: 0;
  }
}

a:hover {
  text-decoration: none !important;
  color: #1489CC !important;
}

a:hover h3 {
  text-decoration: none !important;
  color: #1489CC !important;
}

.item-container .right-container .clickable:hover {
  text-decoration: none !important;
  color: #1489CC !important;
}

.bold-important {
  font-weight: bold !important;
}

::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #9d9d9d;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #848484;
}

@keyframes blink-infinite {
  0% {
    color: #444444;
  }
  25% {
    color: #168fd5;
  }
  50% {
    color: #1f9ee8;
  }
  75% {
    color: #f57825;
  }
  100% {
    color: #f4d82b;
  }
}

.input-wrapper {
  position: relative;
  width: 370px;
  height: 50px;
  margin: 0 auto;
}

.input-wrapper .input-search {
  pointer-events: auto;
  width: inherit;
  height: inherit;
  border: 1px solid #dedede;
  border-radius: 50px;
  background-color: white;
  color: #888888;
  font-family: "Avenir Roman";
  font-size: 14px;
  padding: 15px 50px;
  outline: none !important;
}

.input-wrapper .fas {
  pointer-events: auto;
  color: #9d9d9d;
  font-size: 18px;
  position: absolute;
}

.input-wrapper .fa-search,
.input-wrapper .fa-arrow-left {
  top: 17px;
  left: 20px;
}

.input-wrapper .fa-sliders-h,
.input-wrapper .fa-filter {
  top: 17px;
  right: 20px;
}

.slick-prev,
.slick-next {
  top: 40%;
}

@media (max-width: 480px) {
  .slick-prev,
  .slick-next {
    top: 33%;
  }
}

.slick-prev:before,
.slick-next:before {
  color: #444444;
  font-family: "Font Awesome 5 Free";
  font-weight: 600;
  font-size: 30px;
}

.slick-prev:before {
  content: '\f104';
}

.slick-next:before {
  content: '\f105';
}

.slick-dots li.slick-active button:before {
  color: #168fd5;
  opacity: 1;
}

.near-me .btn-near-me {
  pointer-events: auto;
  height: 38px;
  width: 180px;
  position: relative;
  border: none;
  border-radius: 50px;
  background-color: #168fd5;
  font-family: "Avenir Roman";
  font-size: 13px;
  color: white;
  padding: 10px 15px 10px 35px;
  outline: none !important;
}

.near-me .btn-near-me:hover {
  background-color: #1589cc;
}

.near-me .fas {
  position: absolute;
  color: white;
  font-size: 11px;
}

.near-me .fa-location-arrow {
  top: 13px;
  left: 14px;
  transform: scaleX(-1);
}

.text-loading {
  font-family: 'Avenir Roman';
  font-size: 18px;
  height: 200px;
  width: 100%;
  text-align: center;
  padding-top: 100px;
  color: #168fd5;
}

.btn-cb {
  margin-right: 15px;
  margin-bottom: 5px;
}

.btn-cb .option-label input[type="checkbox"].option-check {
  position: absolute;
  left: -99999px;
}

.btn-cb .option-label input[type="checkbox"].option-check:checked + .cr,
.btn-cb .option-label input[type="checkbox"].option-check:not(:checked) + .cr {
  cursor: pointer;
  width: 130px;
  height: 40px;
  text-align: center;
  padding-top: 9px;
  font-family: 'Avenir Roman';
  font-size: 14px;
}

.btn-cb .option-label input[type="checkbox"].option-check:checked + .cr {
  color: white;
  background-color: #1f9ee8;
  border: 1px solid #1f9ee8;
}

.btn-cb .option-label input[type="checkbox"].option-check:not(:checked) + .cr {
  color: #1f9ee8;
  background-color: white;
  border: 1px solid #1f9ee8;
}

.btn-reg {
  width: 130px;
  height: 40px;
  text-align: center;
  font-family: 'Avenir Roman';
  font-size: 14px;
}

.btn-circ {
  border-radius: 50px;
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.12);
}

.btn-blue {
  color: white;
  background-color: #1f9ee8;
  border: 1px solid #1f9ee8;
}

.btn-blue:hover {
  color: #1f9ee8;
  background-color: white;
  border-color: #1f9ee8;
}

.btn-blue-invert {
  color: #1f9ee8;
  background-color: white;
  border: 1px solid #1f9ee8;
}

.btn-blue-invert:hover {
  color: white;
  background-color: #1f9ee8;
  border-color: #1f9ee8;
}

.ui-container.relative-100 {
  position: relative;
  min-height: 100vh;
}

.ui-container.relative-100 main.explore, .ui-container.relative-100 main.m-parking, .ui-container.relative-100 main.about-us {
  padding-bottom: 82px;
}

.ui-container.relative-100 footer {
  position: absolute;
  bottom: 0;
  width: 100%;
}

h1 {
  font-size: 80px;
}

h3 {
  font-size: 22px;
}

.banner {
  color: white;
  font-size: 12px;
  font-family: "Avenir Roman";
  background-color: #191919;
  padding: 16px 30px;
  position: relative;
}

.banner .fas {
  position: absolute;
  color: white;
  float: right;
  top: 19px;
  right: 15px;
}

header.main-header {
  color: white;
  background-color: #168fd5;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  height: 102px;
  padding: 0 25px;
  overflow: hidden;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.27);
}

header.main-header .left-section {
  width: 35%;
  display: inherit;
}

header.main-header .left-section .left-image {
  margin: auto 0;
}

header.main-header .middle-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-bottom: 34px;
  font-family: garage-gothic, sans-serif;
}

header.main-header .middle-section > div {
  border-bottom: 2px solid white;
  font-size: 26px;
  line-height: 100px;
}

header.main-header .middle-section > div.active {
  border-bottom: 3px solid #f57825;
  font-weight: 600;
}

header.main-header .middle-section .middle-line {
  width: 50px;
}

header.main-header .right-section {
  width: 35%;
  overflow: hidden;
}

header.main-header .right-section .right-image.octopus {
  max-width: 300px;
  padding-top: 10px;
  margin-right: -15px;
}

header.main-header .right-section .right-image.tower {
  height: 125px;
  padding-right: 0;
}

section.big-backdrop {
  height: 557px;
  position: relative;
}

section.big-backdrop .static-map .map-inside-full {
  height: 100%;
}

section.big-backdrop .container {
  padding-top: 100px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%, 0);
}

section.big-backdrop .container.not-clickable {
  pointer-events: none;
}

section.big-backdrop .container .search-box {
  padding-bottom: 25px;
}

section.big-backdrop .container .near-me {
  padding-bottom: 50px;
}

section.big-backdrop .container .headline {
  color: white;
  font-weight: bold;
  font-family: garage-gothic, sans-serif;
  line-height: 75px;
  -webkit-text-fill-color: white;
  /* Will override color (regardless of order) */
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}

section.name-places .container .row > .banner-place .img-banner,
section.name-places .container .row > .banner-add .img-banner {
  width: 100%;
  height: 150px;
  padding-bottom: 20px;
  object-fit: cover;
  max-width: -moz-available;
  max-width: fill-available;
}

section.name-places .container .row .banner-add {
  background-color: #f57825;
  color: white;
  height: 130px;
  padding-top: 5px;
  margin-bottom: 15px;
  font-family: 'Avenir Medium';
  font-size: 20px;
  line-height: 1.2;
}

section.name-places .container .row .banner-place {
  margin-bottom: 15px;
}

section.name-places .container .row .banner-place .subtitle {
  font-family: "Avenir Heavy";
  color: #444444;
}

section.main-map {
  position: relative;
}

section.main-map .geo-error {
  width: 500px;
  position: absolute;
  top: 0;
  left: calc(50% - 250px);
  text-align: center;
  z-index: 1;
  background: white;
  padding: 30px;
  line-height: normal;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.1);
}

section.main-map .geo-error .text {
  font-family: 'Avenir Roman';
  font-size: 18px;
  color: #444444;
}

section.main-map .geo-error .fa-times {
  position: absolute;
  top: 15px;
  right: 20px;
  color: #777777;
}

section.main-map .sidebar {
  position: absolute;
  top: 0;
  left: 0;
  width: 460px;
}

section.main-map .sidebar .side-banner {
  height: 50px;
  padding: 13px 15px;
  background-color: #f57825;
  color: white;
  font-size: 15px;
  margin: 10px 0;
  position: relative;
}

section.main-map .sidebar .side-banner .close-box {
  position: absolute;
  width: 25px;
  height: 25px;
  top: 12px;
  right: -40px;
  background-color: white;
  border: 1px solid #dedede;
}

section.main-map .sidebar .side-banner .close-box .close-wrapper {
  position: relative;
}

section.main-map .sidebar .side-banner .close-box .close-wrapper .fa-times {
  position: absolute;
  top: 4px;
  right: -5px;
  color: #777777;
  font-size: 16px;
}

section.main-map .sidebar .sidebar-content {
  background-color: white;
  overflow-y: auto;
  height: calc(100vh - 222px);
}

section.main-map .sidebar .sidebar-content.wo-banner {
  height: calc(100vh - 172px);
}

section.main-map .sidebar .sidebar-content.wo-banner.wo-side-banner {
  height: calc(100vh - 102px);
}

section.main-map .sidebar .sidebar-content.wo-side-banner {
  height: calc(100vh - 152px);
}

section.main-map .sidebar .sidebar-content .search-box {
  padding-top: 30px;
  padding-bottom: 25px;
}

section.main-map .sidebar .sidebar-content .search-box .input-wrapper .input-search {
  box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.12);
}

section.main-map .sidebar .sidebar-content .near-me {
  padding-bottom: 35px;
}

section.main-map .sidebar .sidebar-content .compare-title {
  height: 70px;
  padding-top: 25px;
  padding-bottom: 25px;
  margin-bottom: 20px;
  position: relative;
  font-family: 'Avenir Black';
  color: #444444;
  font-size: 14px;
  position: relative;
}

section.main-map .sidebar .sidebar-content .compare-title .fa-times {
  position: absolute;
  top: 28px;
  left: 5px;
  color: #777777;
  font-size: 16px;
}

section.main-map .sidebar .sidebar-content .compare-title .text-compare {
  position: absolute;
  top: 25px;
  right: 20px;
}

section.main-map .sidebar .sidebar-content .text-compare {
  font-family: 'Avenir Roman';
  color: #168fd5;
}

section.main-map .sidebar .sidebar-content .text-compare:hover {
  text-decoration: none;
}

section.main-map .sidebar .sidebar-content .text-compare.desktop {
  text-align: right;
  padding-bottom: 20px;
  padding-right: 5px;
}

section.main-map .sidebar .sidebar-content.filter-sort {
  padding-bottom: 40px;
}

section.main-map .sidebar .sidebar-content.filter-sort .filter-title {
  height: 70px;
  padding-top: 25px;
  padding-bottom: 25px;
  position: relative;
  font-family: 'Avenir Black';
  color: #444444;
  font-size: 14px;
}

section.main-map .sidebar .sidebar-content.filter-sort .filter-title .fa-times {
  position: absolute;
  top: 28px;
  left: 5px;
  color: #777777;
  font-size: 16px;
}

section.main-map .sidebar .sidebar-content.filter-sort .button-filter {
  padding-top: 25px;
  padding-bottom: 10px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-pack: start;
      justify-content: flex-start;
}

section.main-map .sidebar .sidebar-content.filter-sort .button-filter button {
  margin-right: 15px;
  margin-bottom: 15px;
}

section.main-map .sidebar .sidebar-content.filter-sort .filter-option {
  padding-bottom: 25px;
}

section.main-map .sidebar .sidebar-content.filter-sort .filter-option .text-title {
  padding-top: 25px;
  padding-bottom: 15px;
  font-family: 'Avenir Black';
  color: #444444;
  font-size: 16px;
}

section.main-map .sidebar .sidebar-content.filter-sort .filter-option .text-option .option-label {
  font-family: 'Avenir Roman';
  color: #444444;
  font-size: 14px;
  position: relative;
  display: block;
}

section.main-map .sidebar .sidebar-content.filter-sort .filter-option .text-option .option-label input[type="checkbox"].option-check {
  position: absolute;
  left: -99999px;
}

section.main-map .sidebar .sidebar-content.filter-sort .filter-option .text-option .option-label input[type="checkbox"].option-check:checked + .cr,
section.main-map .sidebar .sidebar-content.filter-sort .filter-option .text-option .option-label input[type="checkbox"].option-check:not(:checked) + .cr {
  cursor: pointer;
}

section.main-map .sidebar .sidebar-content.filter-sort .filter-option .text-option .option-label input[type="checkbox"].option-check:checked + .cr:before,
section.main-map .sidebar .sidebar-content.filter-sort .filter-option .text-option .option-label input[type="checkbox"].option-check:not(:checked) + .cr:before {
  font-family: "Font Awesome 5 Free";
  position: absolute;
  top: 0;
  right: 20px;
  color: #1f9ee8;
  font-size: 20px;
}

section.main-map .sidebar .sidebar-content.filter-sort .filter-option .text-option .option-label input[type="checkbox"].option-check:checked + .cr:before {
  content: '\f14a';
  font-weight: 900;
}

section.main-map .sidebar .sidebar-content.filter-sort .filter-option .text-option .option-label input[type="checkbox"].option-check:not(:checked) + .cr:before {
  content: '\f0c8';
  font-weight: 400;
}

section.main-map .sidebar .sidebar-content.filter-sort .bottom-button {
  text-align: center;
}

section.main-map .sidebar .sidebar-content.filter-sort .bottom-button button.btn-clear {
  margin-left: 20px;
}

section.parking-detail {
  padding: 0 20px 20px 20px;
}

section.parking-detail .all-section {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

section.parking-detail .all-section .left-section {
  width: 49%;
}

section.parking-detail .all-section .right-section {
  padding-top: 25px;
  width: 49%;
}

section.parking-detail .all-section .right-section .map-full {
  height: 100% !important;
}

section.parking-detail .parking-title {
  min-height: 70px;
  padding: 25px 0;
  position: relative;
  font-family: 'Avenir Black';
  color: #444444;
  font-size: 18px;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

section.parking-detail .parking-title .fa-times {
  color: #777777;
  font-size: 16px;
  padding-top: 4px;
}

section.parking-detail .parking-title .price {
  font-size: 18px;
}

section.parking-detail .distance-and-direction {
  font-family: 'Avenir Roman';
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
  padding-top: 10px;
  font-size: 12px;
  padding-bottom: 12px;
}

section.parking-detail .distance-and-direction .distance {
  width: 50%;
}

section.parking-detail .distance-and-direction .distance .fa-walking {
  font-size: 15px;
  padding-right: 5px;
}

section.parking-detail .distance-and-direction .direction {
  color: #168fd5;
}

section.parking-detail .img-container {
  height: 180px;
  position: relative;
  padding-bottom: 15px;
}

section.parking-detail .img-container .item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: -moz-available;
  max-width: fill-available;
}

section.parking-detail .text-container {
  padding-bottom: 15px;
  font-size: 14px;
}

section.parking-detail .text-container .text-title {
  font-family: 'Avenir Black';
  padding-bottom: 10px;
  line-height: 1.36;
}

section.parking-detail .text-container .text-reg {
  font-family: 'Avenir Roman';
  line-height: 1.36;
}

section.banner-3 {
  background-color: #f57825;
  color: white;
  text-align: center;
  height: 105px;
  font-family: 'Avenir Medium';
  font-size: 15px;
}

section.banner-3 .banner-text {
  padding-top: 18px;
}

section.banner-3 p {
  margin-bottom: 0;
}

section.neighborhood .banner-place .img-banner, section.explore-item .banner-place .img-banner {
  padding-bottom: 10px;
  object-fit: cover;
  max-width: -moz-available;
  max-width: fill-available;
}

section.neighborhood .banner-place .subtitle, section.explore-item .banner-place .subtitle {
  font-family: "Avenir Heavy";
  color: #444444;
}

section.neighborhood {
  padding-top: 50px;
}

section.neighborhood .banner-place .img-banner {
  height: 150px;
  width: 100%;
}

section.neighborhood .banner-place .subtitle {
  font-size: 16px;
}

section.explore-item {
  padding-top: 50px;
}

section.explore-item .container {
  /* Track */
  /* Handle */
  /* Handle on hover */
}

section.explore-item .container .banner-place .img-banner {
  object-fit: fill;
  height: 165px;
  margin: auto;
}

section.explore-item .container::-webkit-scrollbar {
  width: 1px;
}

section.explore-item .container::-webkit-scrollbar-track {
  background: transparent;
}

section.explore-item .container::-webkit-scrollbar-thumb {
  background: transparent;
}

section.explore-item .container::-webkit-scrollbar-thumb:hover {
  background: transparent;
}

section.explore-item .container .banner-place {
  margin: 0 10px;
}

section.explore-item .container .banner-place .subtitle {
  font-size: 16px;
}

section.custom-message {
  padding-top: 50px;
  padding-bottom: 75px;
}

section.custom-message .text-link {
  color: #444444;
  font-size: 14px;
  font-family: 'Avenir Black';
}

section.custom-message form.form-section .form-group {
  width: 600px;
  margin: auto;
  text-align: center;
}

section.custom-message form.form-section .form-group label.form-label {
  text-align: left;
  display: block;
  color: #444444;
  font-size: 14px;
  font-family: 'Avenir Black';
  margin-bottom: 5px;
  padding-left: 20px;
}

section.custom-message form.form-section .form-group .form-input {
  width: 100%;
  border: 1px solid #dedede;
  border-radius: 50px;
  background-color: white;
  color: #888888;
  font-family: "Avenir Roman";
  font-size: 14px;
  padding: 10px 15px;
  outline: none !important;
}

section.custom-message form.form-section .form-btn {
  font-family: "Avenir Roman";
  width: 140px;
  padding: 7px 0;
  margin-bottom: 10px;
}

section.custom-message .loading-box,
section.custom-message .success-box,
section.custom-message .error-box {
  font-family: "Avenir Roman";
}

section.custom-message .loading-box {
  color: #168fd5;
}

section.custom-message .success-box {
  color: #168fd5;
}

section.custom-message .error-box {
  color: #d51630;
  padding: 10px 15px 0 15px;
}

section.about-us-content {
  padding-top: 50px;
  padding-bottom: 30px;
}

section.about-us-content .container .row .col.par {
  padding-bottom: 20px;
}

section.about-us-content .container .row .col.par .title {
  font-family: "Avenir Heavy";
  color: #444444;
  font-size: 22px;
  margin-bottom: 10px;
}

section.about-us-content .container .row .col.par .text {
  font-family: "Avenir Roman";
  font-size: 18px;
  text-align: justify;
}

.top-margin footer.main-footer {
  margin-top: 25px !important;
}

footer.main-footer {
  padding: 0 20px 30px 20px;
  width: 100%;
}

footer.main-footer .special-border {
  border-top: solid 3px #eaeaea;
  padding-bottom: 25px;
}

footer.main-footer .item-footer {
  text-align: center;
}

footer.main-footer .item-footer .text-link {
  font-family: 'Avenir Roman';
  font-size: 12px;
  color: #444444;
}

footer.main-footer .item-footer .text-link.w-margin {
  margin-right: 25px;
}

.item-container {
  pointer-events: auto;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: row;
      flex-direction: row;
  -ms-flex-pack: start;
      justify-content: flex-start;
  margin-top: 25px;
  border: 1px solid #eaeaea;
  border-radius: 10px;
  padding: 15px;
}

.item-container.stand-alone {
  background-color: white;
  bottom: 20px;
  position: absolute;
  width: 94%;
  margin: 0 3%;
}

.item-container.stand-alone .fa-times {
  position: absolute;
  top: 15px;
  right: 15px;
}

.item-container.stand-alone .left-container {
  width: 30%;
}

.item-container.stand-alone .right-container {
  width: 70%;
}

.item-container.stand-alone .right-container .title-and-price {
  -ms-flex-direction: column-reverse;
      flex-direction: column-reverse;
}

.item-container.stand-alone .right-container .title-and-price .title {
  width: 100%;
}

.item-container.stand-alone .right-container .title-and-price .price {
  width: 100%;
  text-align: unset;
}

.item-container.stand-alone .right-container .distance-and-direction .distance {
  width: 120px;
}

.item-container.first-item {
  margin-top: 0;
}

.item-container .left-container {
  width: 125px;
  height: 125px;
  position: relative;
}

.item-container .left-container .item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: -moz-available;
  max-width: fill-available;
  border-radius: 10px;
}

.item-container .left-container .fa-stack {
  position: absolute;
  top: 0;
  right: 0;
}

.item-container .left-container .fa-stack .fas, .item-container .left-container .fa-stack .far {
  font-size: 20px;
}

.item-container .left-container .fa-stack .fa-circle {
  color: #d1d1d1;
}

.item-container .left-container .fa-stack .fa-circle.active {
  color: #f4d82b;
}

.item-container .left-container .fa-stack .fa-check-circle {
  color: white;
}

.item-container .right-container {
  width: 260px;
  padding-left: 15px;
}

.item-container .right-container .text-black {
  color: #444444;
  font-family: 'Avenir Black';
  font-size: 14px;
  line-height: normal;
}

.item-container .right-container .text-reg {
  color: #444444;
  font-family: 'Avenir Roman';
  font-size: 14px;
  line-height: normal;
}

.item-container .right-container .text-reg-light {
  color: #444444;
  font-family: 'Avenir Roman';
  font-size: 12px;
  line-height: normal;
}

.item-container .right-container .text-blue {
  color: #168fd5;
  font-family: 'Avenir Roman';
  font-size: 12px;
  line-height: normal;
}

.item-container .right-container .title-and-price {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
      justify-content: space-between;
}

.item-container .right-container .title-and-price .title {
  width: 80%;
}

.item-container .right-container .title-and-price .title:hover {
  text-decoration: underline;
}

.item-container .right-container .title-and-price .price {
  text-align: right;
}

.item-container .right-container .distance-and-direction {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: start;
      justify-content: flex-start;
  padding-top: 10px;
}

.item-container .right-container .distance-and-direction .distance {
  width: 50%;
}

.item-container .right-container .distance-and-direction .distance .fa-walking {
  font-size: 15px;
  padding-right: 5px;
}

.item-container .right-container .distance-and-direction .direction {
  text-align: left;
}

.map-full {
  height: calc(100vh - 152px);
}

.map-full.wo-banner {
  height: calc(100vh - 102px);
}

.map-full .map-inside-full {
  height: 100%;
}

main.about-us {
  min-height: 80vh;
}

.popup {
  position: absolute;
  top: 100px;
  left: 0;
  right: 0;
  margin: 0 auto;
  width: 85%;
  padding: 0 20px;
  max-width: 600px;
  height: 357px;
  background-color: #F57825;
  text-align: center;
  z-index: 1000000;
  cursor: pointer;
}

.popup .popup-content {
  font-weight: 600;
  padding-top: 140px;
  font-size: 16px;
  position: relative;
}

@media (min-width: 993px) {
  .popup .popup-content {
    font-size: 22px;
  }
}

.popup .popup-content .close-sign {
  position: absolute;
  top: 10px;
  right: 15px;
  color: white;
}

.popup .popup-content .text {
  color: white;
}

.popup .popup-content .text a {
  color: white;
}

.popup .popup-content .text a:hover {
  color: white;
}

@media (min-width: 993px) {
  section.main-map .sidebar .sidebar-content {
    padding-bottom: 20px;
  }
}

@media (max-width: 992px) {
  .map-full {
    height: calc(100vh - 284px);
  }
  .map-full.wo-banner {
    height: calc(100vh - 234px);
  }
  .map-full.iphone-x {
    height: calc(100vh - 397px);
  }
  .map-full.iphone-x.wo-banner {
    height: calc(100vh - 347px);
  }
  .map-full.iphone {
    height: calc(100vh - 359px);
  }
  .map-full.iphone.wo-banner {
    height: calc(100vh - 309px);
  }
  header.main-header {
    display: block;
    position: relative;
  }
  header.main-header .left-section .left-image {
    margin: unset;
    margin-top: 5px;
  }
  header.main-header .middle-section {
    position: absolute;
    bottom: 5px;
    left: 50%;
    transform: translate(-50%, 0);
    padding-bottom: unset;
  }
  header.main-header .middle-section > div {
    font-size: 18px;
    line-height: 30px;
  }
  header.main-header .right-section {
    width: unset;
    position: absolute;
    top: 5px;
    right: 0;
  }
  header.main-header .right-section .right-image.tower {
    height: 100px;
    padding-right: 15px;
  }
  section.main-map .geo-error {
    width: 100%;
    left: 0;
    padding: 40px 30px;
  }
  section.main-map .geo-error .text {
    font-size: 14px;
  }
  section.main-map .geo-error .fa-times {
    top: 12px;
    right: 12px;
  }
  section.main-map .sidebar {
    bottom: 0;
    width: 100%;
    pointer-events: none;
  }
  section.main-map .sidebar .side-banner {
    margin: unset;
  }
  section.main-map .sidebar .side-banner .close-box {
    pointer-events: auto;
    right: 10px;
    border: unset;
    background-color: transparent;
  }
  section.main-map .sidebar .side-banner .close-box .close-wrapper .fa-times {
    color: white;
  }
  section.main-map .sidebar .outside {
    pointer-events: none;
  }
  section.main-map .sidebar .outside .search-box {
    padding-top: 20px;
  }
  section.main-map .sidebar .outside .near-me {
    padding-top: 20px;
  }
  section.main-map .sidebar .parking-opener {
    pointer-events: auto;
    font-size: 14px;
    font-family: 'Avenir Roman';
    color: #444444;
    text-align: center;
  }
  section.main-map .sidebar .parking-opener.outside {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.05);
    position: absolute;
    bottom: 0;
    background-color: white;
    height: 105px;
    width: 100%;
  }
  section.main-map .sidebar .parking-opener.inside {
    padding-bottom: 20px;
  }
  section.main-map .sidebar .parking-opener.inside.to-open {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.05);
    padding-bottom: 0;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: white;
  }
  section.main-map .sidebar .parking-opener.inside.to-open .specific-class footer.main-footer {
    padding: 0 0 30px 0;
    height: 80px;
    background-color: white;
  }
  section.main-map .sidebar .parking-opener .as-hr {
    width: 65px;
    background-color: #d8d8d8;
    height: 5px;
    border-radius: 10px;
    border: 1px solid #d8d8d8;
    margin: 23px auto;
  }
  section.main-map .sidebar .parking-opener .text-compare {
    position: absolute;
    top: 50px;
    right: 20px;
  }
  section.main-map .sidebar .sidebar-content {
    pointer-events: auto;
    height: 100vh;
    top: 0;
    position: fixed;
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    box-shadow: none;
    max-width: 100%;
  }
  section.main-map .sidebar .sidebar-content.iphone-x {
    padding-bottom: 113px;
  }
  section.main-map .sidebar .sidebar-content.iphone {
    padding-bottom: 70px;
  }
  section.main-map .sidebar .sidebar-content.opener {
    bottom: 0;
    position: fixed;
    height: 50px !important;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    box-shadow: 0 -5px 10px 0 rgba(0, 0, 0, 0.05);
    overflow-y: hidden;
    margin-bottom: unset;
    padding-bottom: unset;
  }
  section.main-map .sidebar .sidebar-content .compare-title {
    margin-bottom: unset;
  }
  section.main-map .sidebar .sidebar-content.full-compare {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
  }
  section.main-map .sidebar .sidebar-content.full-compare .item-container {
    border-radius: unset;
    border: none;
    border-bottom: 3px solid #eaeaea;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
        flex-direction: column;
    padding: 15px 0;
    margin: 0 15px;
  }
  section.main-map .sidebar .sidebar-content.full-compare .item-container.last-item {
    border-bottom: unset;
  }
  section.main-map .sidebar .sidebar-content.full-compare .item-container .left-container {
    width: 100%;
  }
  section.main-map .sidebar .sidebar-content.full-compare .item-container .left-container .item-image {
    border-radius: unset;
  }
  section.main-map .sidebar .sidebar-content.full-compare .item-container .right-container {
    width: 100%;
    padding-left: unset;
    padding-top: 10px;
  }
  section.main-map .sidebar .sidebar-content.full-compare .item-container .right-container .distance-and-direction {
    -ms-flex-pack: justify;
        justify-content: space-between;
  }
  section.main-map .sidebar .sidebar-content.full-compare .item-container .right-container .distance-and-direction .distance {
    width: 120px;
  }
  section.main-map .sidebar .sidebar-content.filter-sort {
    border-top-left-radius: unset;
    border-top-right-radius: unset;
    padding-bottom: 0;
  }
  section.main-map .sidebar .sidebar-content.filter-sort .bottom-button {
    padding-bottom: 20px;
  }
  section.main-map .sidebar .sidebar-content.filter-sort.iphone-x {
    padding-bottom: 113px;
  }
  section.main-map .sidebar .sidebar-content.filter-sort.iphone {
    padding-bottom: 70px;
  }
  section.main-map .sidebar .sidebar-content .near-me {
    pointer-events: auto;
  }
  section.main-map .sidebar .sidebar-content .item-container .left-container {
    width: 30%;
  }
  section.main-map .sidebar .sidebar-content .item-container .right-container {
    width: 70%;
  }
  section.main-map .sidebar .sidebar-content .item-container .right-container .distance-and-direction .distance {
    width: 120px;
  }
  section.explore-item .container .banner-place .img-banner {
    height: 135px;
  }
  section.explore-item .container .banner-place .subtitle {
    font-size: 14px;
  }
  section.parking-detail .all-section {
    display: unset;
  }
  section.parking-detail .all-section .left-section {
    width: 100%;
  }
  section.parking-detail .all-section .right-section {
    display: none;
  }
  section.custom-message form.form-section .form-group {
    width: 400px;
  }
  .home-mobile footer.main-footer {
    padding-left: 0;
    padding-right: 0;
  }
}

@media (max-width: 480px) {
  .banner {
    padding: 15px 5px;
  }
  header.main-header {
    padding: 0 5px;
  }
  header.main-header .left-section .left-image {
    height: 70px;
  }
  header.main-header .middle-section .middle-line {
    width: 30px;
  }
  header.main-header .right-section .right-image.tower {
    padding-right: 10px;
  }
  main.explore {
    min-height: 80vh;
  }
  section.main-map .sidebar .sidebar-content .item-container .left-container {
    width: 50%;
  }
  section.main-map .sidebar .sidebar-content .item-container .right-container .distance-and-direction .distance {
    width: 55%;
  }
  section.main-map .sidebar .sidebar-content.full-compare .item-container .right-container .distance-and-direction .distance {
    width: 55%;
  }
  section.custom-message form.form-section .form-group {
    width: 200px;
  }
  section.explore-item {
    width: 90%;
    margin: 0 auto;
  }
  section.explore-item .container .banner-place .img-banner {
    height: 90px;
  }
  .item-container.stand-alone .left-container {
    width: 40%;
  }
  .item-container.stand-alone .right-container {
    width: 50%;
  }
  .item-container.stand-alone .right-container .distance-and-direction .distance {
    width: 50%;
  }
}

@media (max-width: 320px) {
  .input-wrapper {
    width: 300px;
  }
}

